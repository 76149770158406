import React from "react"
import SEO from "../components/seo"
import {Helmet} from "react-helmet";
import {Col, Container, Row} from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../layout2.css"

const IndexPage = props => {

    const { content, title, excerpt, slug, date, author, seo } = props.pageContext

    return (
        <div>
            <Helmet
                title={title}
                defer={false}
            >
            </Helmet>

            <SEO title={seo.title} description={seo.metaDesc} />

            <Container>
                <Header />
            </Container>
            <Container>
                <article dangerouslySetInnerHTML={{ __html: content}} />
            </Container>
            <Footer />
        </div>
    )
}

export default IndexPage